<template>
 <v-container>
    <div class="divContrato">
      <v-data-table 
        :items="contratos"
        :headers="headers"
        :items-per-page="15"
      >

        <template v-slot:[`item.action`]="{ item }">
          <div style="display:flex; align-items: center;">
            <v-btn  small class="ml-2" style="margin: 0 0 0 0" @click="$router.push({name: 'EditarContrato', params: item })">
              <v-icon style="margin: 0 0 0 0" color="green" title="Editar">
                mdi-pencil
              </v-icon>
            </v-btn>
  
            <button class="buttonDeletePrioridade" type="butto" @click="() => confirmarDeletarContrato(item)">
              <img style="margin: 6px 0 0 10px!important; width: 30px;" class="iconeDeletarPrioridade" src="/Prioridades/deletarPrioridade.png" alt="deletar prioridade">
            </button>
          </div>
        </template>

      </v-data-table>
      <button class="button" @click="$router.push('/cadastrar/contrato')">Voltar</button>
    </div>

    <!-- <v-dialog v-model="modalCriarTipos" width="600px" max-width="50vw" persistent>
      <div style="background-color:white;width:100%;display:flex;flex-direction:column; padding: 10px; justify-content: center; align-items: center;text-align: center;">
          <h2 style="margin: 15px 0 15px 0">Cadastrar tipos de stackholders</h2>
          <input class="inputName" type="text" placeholder="Nome" v-model="stackholder.nome"/>
          <div style="display: flex; flex-direction: row; width: 100%; padding: 5px;">
            <input type="checkbox" v-model="stackholder.cliente" style="margin: 0 5px 0 0" id="tipoCliente" name="tipoCliente" checked>
            <label for="tipoCliente">Tipo cliente</label>
          </div>
          <div style="display: flex; width: 100%; justify-content: end;">
            <button class="button" @click="() => this.modalCriarTipos = false">Cancelar</button>
            <button class="button" style="margin-left: 10px;" @click="cadastrarStackHoldersTipos">{{ textoBotaoCadastrar }}</button>
          </div>
      </div>
    </v-dialog> -->

    <v-dialog v-model="alerta" width="300px" max-width="50vw" persistent>
      <div style="background-color:white;width:100%;display:flex;flex-direction:column; padding: 10px; justify-content: center; align-items: center;text-align: center;">
          <h2>Atenção</h2>
          <p>{{ mensagem }}</p>
          <div style="display:flex; justify-content: space-around; width: 100%;">
            <button class="button" @click="() => this.alerta = false">NÃO</button>
            <button class="button" @click="deletarContrato">SIM</button>
          </div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
  import termo_de_adesaoService from '../../services/termo_de_adesao.service';
  export default {
    data() {
      return {
        headers: [
        { id: 'Id', value: 'id' },
        { text: 'Titulo', value: 'titulo'},
        { text: 'Ação', value: 'action'},
      ],
      contratos: [],
      alerta: false,
      mensagem: null,
      contratoDeletar: null,
      }
    },
    methods: {
      async buscarContratos() {
        try {
          const result = await termo_de_adesaoService.buscarContratosCompletos();
          console.log(result.data);
          this.contratos = result.data;
        } catch (error) {
          console.log(error.response);
        }
      },

      confirmarDeletarContrato(item) {
        this.mensagem = 'Deseja deletar esse contrato?'
        this.alerta = true;
        this.contratoDeletar = item;
      },

      async deletarContrato() {
        this.alerta = false;
        try {
          await termo_de_adesaoService.deletarContrato(this.contratoDeletar);
          await this.buscarContratos();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async mounted() {
      await this.buscarContratos();
    }
  }
</script>

<style lang="scss" scoped>
.divContrato {
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  box-shadow:  4px 4px #d9d9d9;
  padding: 3em 1em 1em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.inputName {
  background-color: white;
  border: 1px solid rgb(122, 122, 122);
  margin: 0 0 10px;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
}

.button {
  background-color: #3A78F2;
  color: white;
  padding: 5px;
  width: 100px;
  border-radius: 5px;
  margin: 10px 0 0 0;
}

.buttonEditar {
  background-color: #3A78F2;
  color: white;
  padding: 5px;
  width: 100px;
  border-radius: 5px;
}

.header{
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 80vw;
  max-width: 900px;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
  background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn{
      padding: .75em 1em;
      line-height: 1.5;
      color: white;
      font-weight: bold;
      letter-spacing: 0.02em;
      background: #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
  }
</style>